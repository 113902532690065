import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  HStack,
  Input,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import {
  fetchListPools,
  fetchListTransactions,
  PoolData,
  TransactionData,
} from "../api/pool";
import { getPinkSaleDegenUrl } from "../utils";

type SortConfig = {
  key: string;
  direction: "ascending" | "descending" | null;
};

export const Dashboard: React.FC = () => {
  const _chainId = new URLSearchParams(window.location.search).get("chainId");
  const chainId = Number(_chainId) || 501424;
  const secretFromLocal = localStorage.getItem("secret");
  const secret =
    new URLSearchParams(window.location.search).get("secret") ||
    secretFromLocal ||
    undefined;
  const baseUrl =
    new URLSearchParams(window.location.search).get("baseUrl") || undefined;
  const limit =
    Number(new URLSearchParams(window.location.search).get("limit")) || 20;

  const [solBalance, setSolBalance] = useState<number>(10.5); // Mock value
  const [boughtTokensCount, setBoughtTokensCount] = useState<number>(100); // Mock value
  const [holdingTokensCount, setHoldingTokensCount] = useState<number>(50); // Mock value
  const [cashOutTokensCount, setCashOutTokensCount] = useState<number>(30); // Mock value
  const [cutLossTokensCount, setCutLossTokensCount] = useState<number>(20); // Mock value

  // Pool state
  const [pools, setPools] = useState<PoolData[]>([]);
  const [poolsPage, setPoolsPage] = useState<number>(1);
  const [poolsTotalPages, setPoolsTotalPages] = useState<number>(1);
  const [poolsSortConfig, setPoolsSortConfig] = useState<SortConfig | null>(
    null
  );

  // Transaction state
  const [transactions, setTransactions] = useState<TransactionData[]>([]);
  const [transactionsPage, setTransactionsPage] = useState<number>(1);
  const [transactionsTotalPages, setTransactionsTotalPages] =
    useState<number>(1);
  const [transactionsSortConfig, setTransactionsSortConfig] =
    useState<SortConfig | null>(null);
  const [tokenFilter, setTokenFilter] = useState<string>("");

  useEffect(() => {
    if (secret) localStorage.setItem("secret", secret);
  }, [secret]);

  // Fetch pools
  useEffect(() => {
    const fetchPoolsData = async () => {
      try {
        const response = await fetchListPools(
          {
            chainId: Number(chainId),
            page: poolsPage,
            limit: limit,
          },
          { secret, baseUrl }
        );
        setPools(response.data.docs);
        setPoolsTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching pools", error);
      }
    };

    fetchPoolsData();
  }, [poolsPage]);

  // Fetch transactions
  useEffect(() => {
    const fetchTransactionsData = async () => {
      try {
        const response = await fetchListTransactions(
          {
            chainId: Number(chainId),
            token: tokenFilter,
            page: transactionsPage,
            limit: limit,
          },
          { secret, baseUrl }
        );
        setTransactions(response.data.docs);
        setTransactionsTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching transactions", error);
      }
    };

    fetchTransactionsData();
  }, [transactionsPage, tokenFilter]);

  const sortedPools = [...(pools || [])].sort((a, b) => {
    if (!poolsSortConfig || poolsSortConfig.direction === null) return 0;
    const { key, direction } = poolsSortConfig;
    const aValue = (a as any)[key];
    const bValue = (b as any)[key];

    if (aValue < bValue) return direction === "ascending" ? -1 : 1;
    if (aValue > bValue) return direction === "ascending" ? 1 : -1;
    return 0;
  });

  const sortedTransactions = [...(transactions || [])].sort((a, b) => {
    if (!transactionsSortConfig || transactionsSortConfig.direction === null)
      return 0;
    const { key, direction } = transactionsSortConfig;
    const aValue = (a as any)[key];
    const bValue = (b as any)[key];

    if (aValue < bValue) return direction === "ascending" ? -1 : 1;
    if (aValue > bValue) return direction === "ascending" ? 1 : -1;
    return 0;
  });

  const handlePageChange = (
    setPage: React.Dispatch<React.SetStateAction<number>>,
    page: number,
    totalPages: number
  ) => {
    if (page > 0 && page <= totalPages) {
      setPage(page);
    }
  };

  const handleSort = (
    key: string,
    setSortConfig: React.Dispatch<React.SetStateAction<SortConfig | null>>,
    sortConfig: SortConfig | null
  ) => {
    let direction: "ascending" | "descending" | null = "ascending";
    if (sortConfig && sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        direction = "descending";
      } else if (sortConfig.direction === "descending") {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
  };

  if (!secret) {
    return (
      <Container maxW="container.xl" py={6}>
        <Heading as="h1" mb={6}>
          Please enter secret key to view dashboard
          <Input placeholder="Secret key" value={secret} mt={4} id="secret" />
          <Button
            onClick={() => {
              const secret = (
                document.getElementById("secret") as HTMLInputElement
              ).value;
              window.location.href = `?chainId=${chainId}&secret=${secret}`;
            }}
            mt={4}
            defaultValue={secret}
          >
            Submit
          </Button>
        </Heading>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={6}>
      <Heading as="h1" mb={6}>
        Dashboard
      </Heading>

      <Container maxW="container.xl" py={6}>
        <Heading as="h1" mb={6}>
          Please enter secret key to view dashboard
          <Input
            placeholder="Secret key"
            defaultValue={secret}
            mt={4}
            id="secret"
          />
          <Button
            onClick={() => {
              const secret = (
                document.getElementById("secret") as HTMLInputElement
              ).value;
              window.location.href = `?chainId=${chainId}&secret=${secret}`;
            }}
            mt={4}
            defaultValue={secret}
          >
            Submit
          </Button>
        </Heading>
      </Container>

      <Box mt={10}>
        <Heading as="h2" size="lg" mb={4}>
          Pools
        </Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Pool ID</Th>
              <Th>Token</Th>
              <Th>Buy/Init</Th>
              <Th>Sold</Th>
              <Th>Wallet</Th>
              <Th>Tax & Fee</Th>
              <Th>Profit (SOL)</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedPools.map((pool) => {
              const fee = 1;
              const buyTaxRate =
                (Number(pool?.info?.pool?.buyFeeRate) || 0) / 100;
              const sellTaxRate =
                (Number(pool?.info?.pool?.sellFeeRate) || 0) / 100;

              const initialRaised = Number(pool?.initialRaised) || 0;

              const _quoteAmountBuy = Number(pool?.info?.quoteAmountBuy) || 0;
              const buyFee = _quoteAmountBuy * (fee / 100);
              const buyTax = _quoteAmountBuy * (buyTaxRate / 100);
              const quoteAmountBuy = _quoteAmountBuy + buyTax + buyFee;

              const quoteAmountSell = Number(pool?.info?.quoteAmountSell) || 0;
              const sellFee = (quoteAmountSell / (100 - fee)) * fee;
              const sellTax =
                (quoteAmountSell / (100 - sellTaxRate)) * sellTaxRate;

              const baseBalance = Number(pool?.info?.baseBalance) || 0;
              const currentPrice = Number(pool?.info?.pool?.price?.native) || 0;

              const _valueLeft = baseBalance * currentPrice;
              const valueLeftTax = _valueLeft * (sellTaxRate / 100);
              const valueLeftFee = _valueLeft * (fee / 100);
              const valueLeft = _valueLeft - valueLeftTax - valueLeftFee;

              const taxAndFee =
                buyTax +
                buyFee +
                sellTax +
                sellFee +
                valueLeftTax +
                valueLeftFee +
                valueLeftTax;

              const profit = quoteAmountSell + valueLeft - quoteAmountBuy;
              const isSoldAll = ["sold_all", "cut_loss_done"].includes(
                pool?.step
              );
              const backgroundColor = isSoldAll ? "lightgray" : undefined;
              return (
                <Tr key={pool._id}>
                  <Td
                    color={"blue"}
                    textDecoration="underline"
                    backgroundColor={backgroundColor}
                  >
                    <Link
                      href={getPinkSaleDegenUrl(chainId, pool.token)}
                      isExternal
                    >
                      {/* Cut string, get 4 head and 4 tail characters */}
                      {pool.token.slice(0, 4) + "..." + pool.token.slice(-4)}
                    </Link>
                  </Td>
                  <Td backgroundColor={backgroundColor}>
                    {pool?.info?.pool.tokenData?.symbol}({pool.step})
                  </Td>

                  <Td backgroundColor={backgroundColor}>
                    {quoteAmountBuy}SOL/
                    {initialRaised}SOL
                  </Td>
                  <Td backgroundColor={backgroundColor} color={"green"}>
                    {+quoteAmountSell.toFixed(4)} SOL
                  </Td>
                  <Td backgroundColor={backgroundColor}>
                    {baseBalance.toFixed(1)}({+valueLeft.toFixed(4)}SOL)
                  </Td>
                  <Td backgroundColor={backgroundColor} color={"red"}>
                    {+taxAndFee.toFixed(4)} SOL
                  </Td>
                  <Td
                    backgroundColor={backgroundColor}
                    color={profit < 0 ? "red" : "green"}
                  >
                    {+profit.toFixed(4)} SOL
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <HStack mt={4} justifyContent="center">
          <Button
            onClick={() =>
              handlePageChange(setPoolsPage, poolsPage - 1, poolsTotalPages)
            }
            disabled={poolsPage === 1}
          >
            Previous
          </Button>
          {[...Array(poolsTotalPages)].map((_, i) => (
            <Button
              key={i + 1}
              onClick={() =>
                handlePageChange(setPoolsPage, i + 1, poolsTotalPages)
              }
              variant={poolsPage === i + 1 ? "solid" : "outline"}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            onClick={() =>
              handlePageChange(setPoolsPage, poolsPage + 1, poolsTotalPages)
            }
            disabled={poolsPage === poolsTotalPages}
          >
            Next
          </Button>
        </HStack>
      </Box>

      <Box mt={10}>
        <Heading as="h2" size="lg" mb={4}>
          Transactions
        </Heading>
        <Input
          placeholder="Filter by token"
          value={tokenFilter}
          onChange={(e) => setTokenFilter(e.target.value)}
          mb={4}
        />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Token</Th>
              <Th>Status</Th>
              <Th>Type</Th>
              <Th>AmountIn</Th>
              <Th>MinAmountOut</Th>
              <Th>Created At</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedTransactions.map((tx, index) => {
              const amountIn = (Number(tx.payload?.amountIn) || 0) / 10 ** 9;
              const minAmountOut =
                (Number(tx.payload?.minAmountOut) || 0) / 10 ** 9;
              return (
                <Tr key={tx._id}>
                  <Td color={"blue"} textDecoration="underline">
                    <Link
                      href={getPinkSaleDegenUrl(chainId, tx.token)}
                      isExternal
                    >
                      {/* Cut string, get 4 head and 4 tail characters */}
                      {tx.token.slice(0, 4) + "..." + tx.token.slice(-4)}
                    </Link>
                  </Td>
                  <Td color={tx.status === "error" ? "red" : undefined}>
                    <Tooltip
                      label={
                        <Box whiteSpace="pre-line">
                          {tx.errorReason || "No error"}
                        </Box>
                      }
                      aria-label="Error Reason Tooltip"
                    >
                      <span>{tx.status}</span>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip
                      label={JSON.stringify(tx.payload || {}, null, 2)}
                      aria-label="Payload"
                    >
                      <span>{tx.type}</span>
                    </Tooltip>
                  </Td>
                  <Td>{+amountIn.toFixed(2)}</Td>
                  <Td>{+minAmountOut.toFixed(2)}</Td>
                  <Td>{new Date(tx.createdAt * 1000).toLocaleString()}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <HStack mt={4} justifyContent="center">
          <Button
            onClick={() =>
              handlePageChange(
                setTransactionsPage,
                transactionsPage - 1,
                transactionsTotalPages
              )
            }
            disabled={transactionsPage === 1}
          >
            Previous
          </Button>
          {[...Array(transactionsTotalPages)].map((_, i) => (
            <Button
              key={i + 1}
              onClick={() =>
                handlePageChange(
                  setTransactionsPage,
                  i + 1,
                  transactionsTotalPages
                )
              }
              variant={transactionsPage === i + 1 ? "solid" : "outline"}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            onClick={() =>
              handlePageChange(
                setTransactionsPage,
                transactionsPage + 1,
                transactionsTotalPages
              )
            }
            disabled={transactionsPage === transactionsTotalPages}
          >
            Next
          </Button>
        </HStack>
      </Box>
    </Container>
  );
};
