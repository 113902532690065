import { AxiosClient } from ".";

export interface ApiResponse<T> {
  docs: T[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

interface TokenData {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  _id: string;
}

interface PoolInfo {
  _id: {
    user: string;
    token: string;
  };
  time: number;
  chainId: number;
  baseAmountBuy: number;
  quoteAmountBuy: number;
  baseAmountSell: number;
  quoteAmountSell: number;
  baseBalance: number;
  totalBuy: number;
  totalSell: number;
  pool: {
    _id: string;
    buyFeeRate: string;
    price: {
      native: number;
      usd: number;
    };
    sellFeeRate: string;
    token: string;
    tokenData: TokenData;
  };
}

export interface PoolData {
  _id: string;
  address: string;
  chainId: number;
  token: string;
  createdAt: number;
  initialRaised: string;
  rawInitialBuyAmount: string;
  step: string;
  id: string;
  info: PoolInfo;
}

interface PoolTxData {
  _id: string;
  buyFeeRate: string;
  price: {
    native: number;
    usd: number;
  };
  sellFeeRate: string;
  token: string;
  tokenData: {
    address: string;
    name: string;
    symbol: string;
    decimals: number;
    _id: string;
  };
}

interface Payload {
  price: { native: number; usd: number };
  volume: { native: number; usd: number };
  formattedBaseAmount: string;
  formattedQuoteAmount: string;
  formattedMinAmountOut: string;
  rawInitialBuyAmount: string;
  balance: string;
  amountIn: string;
  amountOut: string;
  minAmountOut: string;
  slippage: number;
  isBuy: boolean;
  signature: string;
}

export interface TransactionData {
  _id: string;
  chainId: number;
  hash: string;
  logIndex: number;
  time: number;
  token: string;
  type: string;
  status: string;
  payload: Payload;
  createdAt: number;
  errorReason: string | null;
}

interface FetchListPoolParams {
  chainId: number;
  page: number;
  limit: number;
}
export function fetchListPools(
  params: FetchListPoolParams,
  options?: { secret?: string; baseUrl?: string }
) {
  return AxiosClient.get<ApiResponse<PoolData>>("/pools", {
    params,

    headers: {
      secret: options?.secret,
    },
    baseURL: options?.baseUrl,
  });
}

interface FetchTransactionParams {
  chainId: number;
  token?: string;
  page: number;
  limit: number;
}
export function fetchListTransactions(
  params: FetchTransactionParams,
  options?: { secret?: string; baseUrl?: string }
) {
  return AxiosClient.get<ApiResponse<TransactionData>>("/pool-transactions", {
    params,
    headers: {
      secret: options?.secret,
    },
    baseURL: options?.baseUrl,
  });
}
