export enum Chain {
  ETH = 1,
  BSC = 56,
  POLYGON = 137,
  AVAX = 43114,
  FANTOM = 250,
  CRONOS = 25,
  DOGE = 2000,
  ARBITRUM = 42161,
  ALVEY = 3797,

  BSC_TESNET = 97,

  PULSE = 369,
  BITROCK = 7171,
  SHIBARIUM = 109,
  CORE = 1116,
  CYBRIA = 6661,

  /* -------------------------------------------------------------------------- */
  /*                                   Solana                                   */
  /* -------------------------------------------------------------------------- */
  SOLANA_DEVNET = 501423,
  SOLANA = 501424,

  BASE = 8453,
}

export function getChainName(chainId: number) {
  switch (chainId) {
    case 1:
      return "ethereum";

    case 56:
      return "bsc";

    case 97:
      return "bsc-test";

    case 137:
      return "polygon";

    case 43114:
      return "avalanche";

    case 250:
      return "fantom";

    case 25:
      return "cronos";

    case 2000:
      return "dogechain";

    case 3797:
      return "alvey";

    case 42161:
      return "arbitrum";

    case 369:
      return "pulsechain";

    case 7171:
      return "bitrock";

    case 109:
      return "shibarium";

    case 1116:
      return "core";

    case 6661:
      return "cybria";

    case 501424:
      return "solana";

    case 501423:
      return "solana-test";

    case 8453:
      return "base";
  }

  return "";
}

export function getPinkSaleDegenUrl(chain: Chain, token: string) {
  if (chain == Chain.SOLANA) {
    return `https://www.pinksale.finance/solana/degen/${token}`;
  } else if (chain == Chain.SOLANA_DEVNET) {
    return `https://www.pinksale.finance/solana/degen/${token}?cluster=devnet`;
  }
  return `https://www.pinksale.finance/degen/${getChainName(chain)}/${token}`;
}
